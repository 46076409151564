import { useState } from "react";

import Character from "../../Character";
import CharacterTalent from "../../../../utils/game/mechanics/character/CharacterTalent";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import LabeledTextField from "../../../../common/components/display/LabeledTextField/LabeledTextField";
import NumberInputToggleField from "../../../../common/components/input/NumberInputToggleField/NumberInputToggleField";
import TextField from "../../../../common/components/display/TextField/TextField";

import "./CharacterTalentPanel_style.css";

type Props = {
    talent: CharacterTalent;
    isEditable?: boolean;
    isEditing?: boolean;
    pendingPointSpend?: number;
    onPendingPointSpendChanged?: (prevValue: number, newValue: number) => void;
    onTalentRemoved?: () => void;
};

export default function CharacterTalentPanel({
    talent,
    isEditable = false,
    isEditing = false,
    pendingPointSpend,
    onPendingPointSpendChanged,
    onTalentRemoved,
}: Props): JSX.Element {
    const pendingRankAndProgress = CharacterTalent.scoreToRankAndProgress(
        talent.score + (pendingPointSpend ?? 0)
    );

    return (
        <FlexRow className="character-trait-panel-container">
            <FlexColumn className="details-container" gap={4}>
                <FlexRow width="full">
                    <FlexRow>
                        <div className="title">{talent.title}</div>
                        <div className="type">{`${talent.subtype} Talent`}</div>
                    </FlexRow>
                    {isEditable === true && (
                        <FlexRow width="auto">
                            <button
                                onClick={(e) => {
                                    onTalentRemoved?.();
                                    e.stopPropagation();
                                }}
                            >
                                X
                            </button>
                        </FlexRow>
                    )}
                </FlexRow>
                <FlexRow gap={8}>
                    <FlexRow gap={8} width={"half"}>
                        <TextField type="bold" style={{ width: "auto" }}>
                            Rank
                        </TextField>
                        <TextField style={{ width: "auto" }}>
                            {isEditing
                                ? pendingRankAndProgress.rank.toString()
                                : talent.rank.toString()}
                        </TextField>
                    </FlexRow>
                    <FlexRow gap={8} width={"half"}>
                        <TextField type="bold" style={{ width: "auto" }}>
                            Progress
                        </TextField>
                        {isEditing && (
                            <div
                                className="talent-subtract-button"
                                onClick={() => {
                                    if (pendingPointSpend === undefined) {
                                        if (talent.score > 0) {
                                            onPendingPointSpendChanged?.(0, -1);
                                        }
                                    } else if (
                                        talent.score + pendingPointSpend >
                                        0
                                    ) {
                                        onPendingPointSpendChanged?.(
                                            pendingPointSpend ?? 0,
                                            (pendingPointSpend ?? 0) - 1
                                        );
                                    }
                                }}
                            >
                                -
                            </div>
                        )}
                        <TextField style={{ width: "auto" }}>{`${
                            pendingRankAndProgress.progress
                        } / ${CharacterTalent.getNextRankCost(
                            pendingRankAndProgress.rank
                        )}`}</TextField>
                        {isEditing && (
                            <div
                                className="talent-add-button"
                                onClick={() => {
                                    if (pendingPointSpend === undefined) {
                                        if (talent.score < 355) {
                                            onPendingPointSpendChanged?.(0, 1);
                                        }
                                    } else if (
                                        talent.score + pendingPointSpend <
                                        355
                                    ) {
                                        onPendingPointSpendChanged?.(
                                            pendingPointSpend ?? 0,
                                            (pendingPointSpend ?? 0) + 1
                                        );
                                    }
                                }}
                            >
                                +
                            </div>
                        )}
                    </FlexRow>
                </FlexRow>
                <div>{talent.description}</div>
            </FlexColumn>
        </FlexRow>
    );
}
